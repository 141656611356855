import React from "react";
import { useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { functions } from "./../config/firebaseConfig";
import QrCodeGenerator from "./QrCodeGenerator";

const User = () => {
  const { id } = useParams(); // Der Parameter 'id' wird hier aus der URL gelesen
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const domain = window.location.origin;

  useEffect(() => {
    const fetchUserData = async () => {
      // const functions = getFunctions();
      const getUserData = httpsCallable(functions, 'llBio/get-user-info');
      try {
        const result = await getUserData({ id });
        setUserData(result.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <p>Parameter:{id}</p>
      <QrCodeGenerator url={`${domain}/${id}`} />
      <div>
      
      
    </div>
      {userData && (
        <div>
          <h2>User Data</h2>
          <pre>{JSON.stringify(userData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default User;